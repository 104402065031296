(function () {
  'use strict';
  var matchesMd = matchMedia('screen and (min-width: 992px)').matches;
  new Maplace({
    locations: [{
      icon: '/assets/img/mapa-pointer-contactos.png',
      lat: 39.28879,
      lon: -9.15589,
      // zoom: 16,
      title: 'Ecofrutas, Lda'
    }],
    map_options: {
      set_center: matchesMd && [39.28879, -9.149],
      zoom: 16,
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControlOptions: {
        position: matchesMd && google.maps.ControlPosition.LEFT_BOTTOM
      }
    }
  }).Load();
})();